<template>
  <div>
    <b-card>
      <div class="row">
        <div class="col-3">
          <b-form-input
            v-model="searchForm.date__gte"
            type="date"
          />
        </div>
        <div class="col-3">
          <b-form-input
            v-model="searchForm.date__lte"
            type="date"
          />
        </div>
        <div class="col-2">
          <button
            class="btn btn-primary"
            @click="fetchData"
          >Показать
          </button>
        </div>
      </div>
      <b-overlay :show="loading">
        <b-table
          :items="Object.values(data)"
          :fields="tableFields"
          class="mt-2"
          small
        />
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import { BCard, BFormInput, BTable, BOverlay } from 'bootstrap-vue'
import moment from 'moment'
import { filterRequestParams } from '@/views/utils'

export default {
  components: {
    BCard,
    BFormInput,
    BTable,
    BOverlay,
  },
  data() {
    return {
      loading: false,
      searchForm: {
        date__gte: null,
        date__lte: null,
        out_key: 'yandex_rent_1',
      },
      dates: [],
      data: {},
      fields: [
        {
          key: 'item',
          label: 'ФИО',
          formatter(item) {
            return `${item.last_name} ${item.first_name} ${item.middle_name}`
          },
          sortable: true,
          sortByFormatted(item) {
            return `${item.last_name} ${item.first_name} ${item.middle_name}`
          },
        },
      ],
    }
  },
  computed: {
    tableFields() {
      const result = this.fields.filter(() => true)
      this.dates.forEach(item => {
        result.push({
          key: item,
          label: moment(item).format('DD.MM.YY'),
          formatter(ln, _, ii) {
            return ii.dates[item]
          },
          sortable: true,
          sortByFormatted(ln, _, ii) {
            return ii.dates[item]
          },
        })
      })
      return result
    },
    search() {
      return this.$store.getters['app/search']
    },
  },
  watch: {
    search() {
      this.fetchData()
    },
  },
  mounted() {
    this.searchForm.date__gte = moment()
      .subtract(6, 'day')
      .format('YYYY-MM-DD')
    this.searchForm.date__lte = moment()
      .format('YYYY-MM-DD')
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      this.dates = []
      this.data = {}
      this.$axios.get('/reports/yandex/trips', { params: { ...filterRequestParams(this.searchForm), search: this.search }, timeout: 60000 })
        .then(response => {
          response.data.data.forEach(item => {
            if (this.data[item.id] === undefined) {
              this.data[item.id] = {
                item,
                dates: {},
              }
            }
            this.data[item.id].dates[item.date] = item.cnt
            if (this.dates.indexOf(item.date) === -1) {
              this.dates.push(item.date)
            }
          })
        })
        .finally(() => {
          this.dates.sort((a, b) => moment(a) - moment(b))
          this.loading = false
        })
    },
  },
}
</script>
